import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { appConstants } from '../../constants/app.constants';
import { AuthenticationService } from '../auth/authentication.service';
import { IbdSdkService } from '../common/ibd-sdk.service';
import { ROLES } from '../../constants/roles.constants';

@Injectable()
export class AuthGuardService  {
  constructor(
    private route: Router,
    public authService: AuthenticationService,
    private cookieService: CookieService,
    private ibdSdkService: IbdSdkService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {  
    if (!this.cookieService.check(appConstants.LOGGED_IN)) {
      const redirectURL = state.url.includes('unsubscribe') ? state.url : '';
      this.route.navigate(['/login'], { queryParams: { redirectUrl: redirectURL } });
      return false;
    } else if (this.cookieService.check(appConstants.LOGGED_IN)) {
      const isAuthExpired =
        await this.ibdSdkService.ibd.authenticationManager.isAuthenticationExpired();
      if (isAuthExpired) {
        const isRefreshExpired =
          this.ibdSdkService.ibd.authenticationManager.refreshAuthentication();
        if (isRefreshExpired == null) {
          //this.authService.logout();
          return false;
        }
      }
      return true;
    }
  }
}

@Injectable()
export class LoggedInUserService  {
  constructor(
    private route: Router,
    private cookieService: CookieService,
    private ibdSdkService: IbdSdkService
  ) {}

  async canActivate(): Promise<boolean> {
    if (
      this.cookieService.check(appConstants.LOGGED_IN) &&
      (await this.ibdSdkService.ibd.authenticationManager.loginCheck())
    ) {
      this.route.navigateByUrl('/home');
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class ResetPasswordService  {
  constructor() {}

  async canActivate(): Promise<boolean> {
    const forgotPwdEmail = localStorage.getItem(appConstants.FORGOT_PWD_EMAIL);
    if (forgotPwdEmail !== null) {
      return true;
    } else {
      return false;
    }
  }
}

import {
  LoggedInUserService,
  AuthGuardService,
  ResetPasswordService,
} from './shared/services/guard/auth.guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/module/login/login.module').then((m) => m.LoginModule),
    canActivate: [LoggedInUserService],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/module/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
    canActivate: [LoggedInUserService],
  },
  {
    path: 'privacy-settings',
    loadChildren: () =>
      import('./pages/module/privacy-settings/privacy-settings.module').then(
        (m) => m.PrivacySettingsModule
      ),
    canActivate: [LoggedInUserService],
  },
  {
    path: 'reset-password', // /:secretCode',
    loadChildren: () =>
      import('./pages/module/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [ResetPasswordService],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/module/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'master-data',
    loadChildren: () =>
      import('./pages/module/home/master-data/master-data.module').then(
        (m) => m.MasterDataModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'assets',
    loadChildren: () =>
      import('./pages/module/home/assets/assets.module').then(
        (m) => m.AssetsModule
      ),
    canActivate: [AuthGuardService],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), // added preloading strategy to avoid delay in loading pages on first time
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
